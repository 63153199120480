import RouteBase from '@/router/routes/RouteBase.js';

class RoutesBusinessPartners {
  subFolderPath = '/businessPartners';
  isPublic = false;

  Pmi = new RouteBase({
    name: 'pmi',
    path: 'pmi',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Pmi',
    isPublic: this.isPublic,
    meta: {
      icon: 'wave',
    },
  });

  Ulker = new RouteBase({
    name: 'ulker',
    path: 'ulker',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Ulker',
    isPublic: this.isPublic,
    meta: {
      icon: 'wave',
    },
  });

  Peyman = new RouteBase({
    name: 'peyman',
    path: 'peyman',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Peyman',
    isPublic: this.isPublic,
    meta: {
      icon: 'wave',
    },
  });

  Horizon = new RouteBase({
    name: 'horizon',
    path: 'horizon',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Horizon',
    isPublic: this.isPublic,
    meta: {
      icon: 'wave',
    },
  });
}

export default new RoutesBusinessPartners();
